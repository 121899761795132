<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 提示信息 -->
            <alert-info :info="infodata"></alert-info>
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">遗产要素单体或局部照片信息</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.saveJson"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="数据密级" prop="sjmj">
                    <el-select
                        v-model="formdata.saveJson.sjmj"
                        placeholder="请选择数据密级"
                        :disabled="!isEdit"
                        @change="changeSJMJ"
                    >
                        <el-option label="公开" value="公开"></el-option>
                        <el-option label="不公开" value="不公开"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    v-show="formdata.saveJson.sjmj === '公开'"
                    label="发布范围"
                    prop="fbfw"
                >
                    <el-select
                        v-model="formdata.saveJson.fbfw"
                        placeholder="请选择发布范围"
                        :disabled="!isEdit"
                    >
                        <el-option
                            label="互联网公开"
                            value="互联网公开"
                        ></el-option>
                        <el-option
                            label="行业内部公开"
                            value="行业内部公开"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="遗产要素" prop="ycysbm">
                    <el-select
                        v-model="formdata.saveJson.ycysbm"
                        filterable
                        clearable
                        placeholder="请选择遗产要素"
                        :disabled="!isEdit"
                        @change="changeYcys"
                    >
                        <el-option
                            v-for="(item, index) in ycysList"
                            :key="index"
                            :label="item.MC"
                            :value="item.BM"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="遗产要素单体" prop="mc">
                    <el-input
                        v-model="formdata.saveJson.mc"
                        :maxlength="50"
                        placeholder="请输入遗产要素单体名称"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="经纬度" class="select_jwd" prop="jwd">
                    <div class="flex">
                        <el-tooltip
                            class="item"
                            effect="light"
                            content="请填写正确的经纬度坐标，经度和纬度用英文的逗号隔开，如：118.056786,24.435336或 118.071978,24.444005；或者在选择遗产地后点击“坐标拾取”按钮，直接获取经度坐标"
                            placement="bottom"
                        >
                            <el-input
                                v-model="formdata.saveJson.jwd"
                                :maxlength="50"
                                placeholder="请输入经纬度"
                                :disabled="!isEdit"
                                style="width: 500px; margin-right: 10px"
                            >
                            </el-input>
                        </el-tooltip>
                        <el-button size="small" @click="showPickup = true"
                            >坐标拾取</el-button
                        >
                    </div>
                </el-form-item>
                <el-form-item label="描述" class="from-item-block">
                    <el-input
                        v-model="formdata.saveJson.bz"
                        placeholder="请输入描述"
                        type="textarea"
                        :autosize="{ minRows: 6 }"
                        :maxlength="500"
                        show-word-limit
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <!-- 文件上传 图片 -->
                <basic-upload
                    v-if="formdata.saveJson.sjmj === '公开'"
                    ref="uploadFile"
                    :itemId="itemId"
                    :metaData.sync="formdata.metaJson"
                    basicType="pictureSpecial"
                    :isEdit="isEdit"
                    :limit="1"
                    :onlyOne="true"
                >
                </basic-upload>
            </el-form>
            <coordinatePickup
                :editState="isEdit"
                :showPickupP.sync="showPickup"
                :coordinate.sync="formdata.saveJson.jwd"
                @clickPoint="clickPoint"
            >
            </coordinatePickup>
        </div>
    </div>
</template>

<script>
import coordinatePickup from "@comp/map/coordinate_pickup";
import alertInfo from "@comp/alert_info";
import basicUpload from "@comp/basic_upload";
import snButton from "../0_com_js/edit-button.js";
import infoMixin from "../0_com_js/info_mixin.js";
import { mapActions } from "vuex";
export default {
    name: "hem_part_image",
    components: {
        alertInfo,
        basicUpload,
        coordinatePickup,
    },
    mixins: [infoMixin],
    data() {
        let checkCoordinate = (rule, value, callback) => {
            let reg2 = /^([0-9.]+,)[0-9.]+$/;
            if (value === "" || !value) {
                callback(new Error("请输入病害经纬度"));
            } else if (!reg2.test(value)) {
                callback(new Error("请输入正确格式的病害经纬度"));
            } else {
                callback();
            }
        };
        return {
            showPickup: false,
            ycysList: [],
            snButton: snButton,
            rules: {
                sjmj: [
                    {
                        required: true,
                        message: "请选择数据密级",
                        trigger: "blur",
                    },
                ],
                fbfw: [
                    {
                        required: true,
                        message: "请选择发布范围",
                        trigger: "blur",
                    },
                ],
                ycysbm: [
                    {
                        required: true,
                        message: "请选择遗产要素",
                        trigger: "change",
                    },
                ],
                wjsc: [
                    {
                        required: true,
                        message: "请上传文件",
                        trigger: "change",
                    },
                ],
                mc: [
                    {
                        required: true,
                        message: "请输入遗产要素单体名称",
                        trigger: "blur",
                    },
                ],
                jwd: [
                    {
                        required: true,
                        validator: checkCoordinate,
                        trigger: "blur",
                    },
                    {
                        required: true,
                        validator: checkCoordinate,
                        trigger: "change",
                    },
                ],
            },
            formdata: {
                saveJson: {
                    sjmj: "公开",
                    fbfw: "行业内部公开",
                    ycysbm: "",
                    ycysmc: "",
                    wjsc: "",
                    mc: "",
                    jwd: "",
                    bz: "",
                    jd: "",
                    wd: "",
                },
                metaJson: [],
                heritageId: "",
                fileID: "",
                originalFileID: "",
                itemID: "14",
                userName: "",
                userId: "",
            },
        };
    },
    computed: {},
    mounted() {
        this.getYcysList();
    },
    methods: {
        ...mapActions(["GetYcysList"]),
        // 获取遗产要素
        async getYcysList() {
            const res = await this.GetYcysList();
            if (res.IsSuccess) {
                this.ycysList = res.ResultValue;
            }
        },
        // 改变遗产要素 获得遗产要素名称
        changeYcys(v) {
            let selected = this.ycysList.filter((item) => item.BM === v);
            this.formdata.saveJson.ycysmc = selected[0].MC;
        },
        // 坐标拾取
        clickPoint() {
            if (this.formdata.saveJson.jwd) {
                let lnglat = this.formdata.saveJson.jwd.split(",") || [];
                this.formdata.saveJson.jd = lnglat[0];
                this.formdata.saveJson.wd = lnglat[1];
            }
        },
    },
};
</script>
<style lang="scss" scoped></style>
